<template>
  <GlPageWrap
    class="users-page-wrapper"
    title="User Management"
  >
    <template #actions>
      <div class="m-pa-2 m-fixed-bottom-wrapper">
        <button
          class="gl-button gl-button--dark gl-button--padder m-fullwidth"
          @click="handleCreate"
        >
          Create new user
        </button>
      </div>
    </template>
    <div class="table-wrap">
      <o-table
        class="table__overflow-auto"
        :class="{ 'o-table-has-pagination': users.length && totalPages > 1 }"
        :data="users"
        hoverable
        :loading="loading"
        :mobile-cards="false"
      >
        <o-table-column
          v-slot="props"
          field="email"
          label="Email"
          width="450"
        >
          {{ props.row.email }}
        </o-table-column>

        <o-table-column
          v-slot="props"
          field="role"
          label="Role"
          width="300"
        >
          {{ props.row.role }}
        </o-table-column>
        <o-table-column
          v-slot="props"
          field="activeTo"
          label="Date active to"
          width="300"
        >
          {{ props.row.activeTo ? formatDate(props.row.activeTo, 'dd.MM.yyyy') : '-' }}
        </o-table-column>
        <o-table-column
          v-slot="props"
          field="activeTo"
          label="Score requests"
          width="300"
        >
          {{ props.row.requestsHistory ? toComaSeparate(props.row.totalScoreRequests) : '-' }}
        </o-table-column>
        <o-table-column
          v-slot="props"
          field="status"
          label="status"
          width="300"
        >
          <div class="flex align-start">
            <gl-active
              v-model="props.row.disabled"
              @input="handleActivateUser($event, props.row._id)"
            />
            <div>
              {{ !props.row.disabled ? 'Active' : 'Disabled' }}
            </div>
          </div>
        </o-table-column>
        <o-table-column
          v-slot="props"
          class="gl-user-dropdown__list-item"
          field="actions"
          label="Actions"
          width="300"
        >
          <div
            v-if="props.row.role !== 'External_System'"
            class="flex"
          >
            <gl-icon
              v-tooltip.top="'Edit user'"
              class="mr-3 pointer"
              :height="24"
              name="edit-block-active"
              :width="24"
              @click="updateUser(props.row)"
            />
            <gl-menu-item
              v-tooltip.top="'Reset the password'"
              fullwidth
              icon="reload"
              :icon-height="24"
              :icon-width="24"
              label=""
              style="margin-left: -5px;"
              @click="handleResetPassword(props.row)"
            />
            <gl-menu-item
              v-tooltip.top="'Delete user'"
              class="ml-2"
              fullwidth
              icon="delete"
              :icon-height="24"
              :icon-width="24"
              label=""
              warn
              @click="deleteModal = true, selectUser = props.row"
            />
          </div>
        </o-table-column>
        <template #empty>
          <div class="empty-users-data flex column align-center">
            <gl-icon
              class="mb-3"
              name="users"
            />
            No users here yet
          </div>
        </template>
      </o-table>
      <o-pagination
        v-if="users.length && totalPages > 1"
        class="users-pagination m-mb-3"
        :current.sync="currentPage"
        order="centered"
        :per-page="perPage"
        :total="total"
        @change="pageChange"
      >
        <o-pagination-button
          slot="previous"
          slot-scope="props"
          :page="props.page"
        >
          <gl-menu-item
            class="change-page mr-2"
            :disabled="props.page.disabled"
            icon="left"
            :icon-height="24"
            :icon-width="24"
            label=""
            not-outline
          />
        </o-pagination-button>

        <o-pagination-button
          slot="next"
          slot-scope="props"
          :page="props.page"
        >
          <gl-menu-item
            class="change-page"
            :disabled="props.page.disabled"
            icon="right"
            :icon-height="24"
            :icon-width="24"
            label=""
            not-outline
          />
        </o-pagination-button>
      </o-pagination>
    </div>
    <createUserModal
      v-if="createUser"
      v-model="createUser"
      :active-to="selectUser.activeTo || null"
      :email="selectUser.email"
      :is-create="isCreate"
      :role="selectUser.role"
      @add="setInvite"
      @close="createUser = false"
      @update="update(selectUser._id, $event)"
    />
    <resetPasswordModal
      v-model="resetPasswordModal"
      :email="selectUser.email"
      @close="resetPasswordModal = false"
      @submit="reset"
    />
    <deleteUserModal
      v-model="deleteModal"
      :data="selectUser"
      @close="deleteModal = false"
      @submit="remove"
    />
  </GlPageWrap>
</template>

<script>
// Utils
import { formatDate } from "@/utils/format-date";
import { toComaSeparate } from "@/utils/formatNumber";
import moment from "moment";
// Vuex
import {mapActions, mapState} from 'vuex';
// Components
import GlIcon from '@/components/gl-icon'
import GlActive from "@/components/gl-active";
import GlMenuItem from '@/components/gl-menu-item'
import GlPageWrap from "@/components/layout/gl-page-wrap";
import createUserModal from "@/pages/users/modals/createUserModal";
import deleteUserModal from "@/pages/users/modals/deleteUserModal";
import resetPasswordModal from "@/pages/users/modals/resetPasswordModal";

export default {
  components: {
    GlIcon,
    GlActive,
    GlMenuItem,
    GlPageWrap,
    deleteUserModal,
    createUserModal,
    resetPasswordModal,
  },
  data() {
    return {
      isCreate: true,
      createUser: false,
      resetPasswordModal: false,
      deleteModal: false,
      selectUser: {},
      loading: false,
      currentPage: 1,
      perPage: 10,
      totalPages: 1,
      total: 1,
      users: []
    }
  },
  computed: {
    ...mapState('user', ['id']),
  },
  async created() {
    await this.getUsers()
  },
  methods: {
    moment,
    ...mapActions({
      logout: 'user/logout',
      editUser: 'users/editUser',
      deleteUser: 'users/deleteUser',
      getUsersList: 'users/getUsersList',
      resetPassword: 'users/resetPassword',
      setActiveUser: 'users/setActiveUser',
      sendInviteUser: 'users/sendInviteUser',
    }),
    formatDate,
    toComaSeparate,
    handleCreate() {
      this.createUser = true
      this.isCreate = true
      this.selectUser = {}
    },
    updateUser(user) {
      this.isCreate = false
      this.selectUser = user
      this.createUser = true
    },
    async getUsers() {
      this.loading = true
      const { data: { items, totalItems } } = await this.getUsersList({ count: this.perPage, skip: (this.currentPage - 1) * this.perPage})
      this.users = items.map(user => ({
        ...user,
        totalScoreRequests: user.requestsHistory ? user.requestsHistory.addressScore + user.requestsHistory.txScore : 0
      }))
      this.total = totalItems
      this.totalPages = Math.ceil(totalItems / this.perPage)
      this.loading = false
    },
    handleActivateUser(val, id) {
      const data = {
        userId: id,
        disabled: val
      }
      this.setActiveUser(data).then(({ success }) => {
        if (success) {
          this.getUsers()
        } else {
          this.$toasted.global.error({ message: `${data.message}` })
        }
      }).catch(({ response: { data } }) => {
        this.$toasted.global.error({ message: `${data.data.message}` })
      })
    },
    reset(email) {
      if (email) {
        this.resetPassword(email).then(() => {
          this.resetPasswordModal = false
          this.$toasted.global.success({ message: `${email} password was succesfully reseted` })
          this.selectUser = {}
        })
      }
    },
    handleResetPassword(data) {
      this.selectUser = data
      this.resetPasswordModal = !this.resetPasswordModal
    },
    pageChange(event) {
      this.currentPage = event
      this.getUsers()
    },
    setInvite(user) {
      this.sendInviteUser(user).then(({ success }) => {
        if (success) {
          this.createUser = false;
          this.$toasted.global.success({ message: `The invite was successfully sent to ${user.email}` })
        }
      }).catch(({ response: { data } }) => {
        this.$toasted.global.error({ message: `${data.data.message}` })
      });
    },
    async update(id, data) {
      await this.editUser({id, body: {
          ...data,
          activeTo: data.activeTo || 0
        }}).then(() => {
        this.selectUser = {}
        this.createUser = false
      })
      await this.getUsers()
    },
    async remove(id) {
      if (id) {
        await this.deleteUser(id).then(() => {
          this.deleteModal = false
          this.$toasted.global.success({ message: 'The user was successfuly deleted' })
          if (this.id === id) {
            this.logout();
            window.location.assign('/');
          }
        })
        await this.getUsers()
      }
    }
  }
}
</script>

<style scoped>
.change-page {
  border: 1px solid var(--dark-grey-d-3);
  border-radius: 3px;
  height: 35px;
  padding-top: 5px;
}
</style>

<style>
@media (max-width: 767px) {
  .users-page-wrapper {
    margin-bottom: 60px;
  }
  .users-page-wrapper .o-pag--mobile .o-pag__previous, .users-page-wrapper .o-pag--mobile .o-pag__next {
    flex-grow: unset;
  }
}
</style>
