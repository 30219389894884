// export const toComaSeparate = (val) => String(val).replace(/(.)(?=(.{3})+$)/g,"$1,")
// export const toComaSeparate = (val) => {
//   return String(val).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
// }

export const toComaSeparate = val => {
  if (!val) return ''
  let parts = String(val).split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

export const restrictNumberAfterComma = (val, count) => {
  if (isNaN(Number(val))) return ''
  return Number(val).toFixed(count).replace(/\.?0+$/,"");
};

export const toNumber = (val) => {
  return Number(val.replace(/,/g, ""))
}

export const toDecimalVal = (amount, decimal) => {
  const defDecimal = 18

  return Number(amount) / Math.pow(10, decimal || defDecimal)
}
