<template>
  <gl-modal
    v-bind="$attrs"
    capitalize-title
    submit-title="Delete"
    title="Delete the User"
    width="400"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="onSubmit"
  >
    <div class="gl-modal__row">
      You are going to delete <strong>{{ data.email }}</strong>. <br>This action cannot be undone.
    </div>
  </gl-modal>
</template>

<script>
import GlModal from '@/components/gl-modal'

export default {
  components: {
    GlModal,
  },
  inheritAttrs: false,
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    onSubmit() {
      this.$emit('submit', this.data._id)
    },
  },
}
</script>
