<template>
  <gl-modal
    v-bind="$attrs"
    capitalize-title
    submit-title="Reset"
    title="Reset the Password"
    width="400"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="onSubmit"
  >
    <div class="gl-modal__row">
      You are going to reset the password for <strong>{{ email }}</strong>.
      The user will receive the reset link and further instructions via email.
    </div>
  </gl-modal>
</template>

<script>
import GlModal from '@/components/gl-modal'

export default {
  components: {
    GlModal,
  },
  inheritAttrs: false,
  props: {
    email: {
      type: String,
      default: '',
    },
  },
  methods: {
    onSubmit() {
      this.$emit('submit', this.email)
    },
  },
}
</script>